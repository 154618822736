import { Layout } from "../../components/Layout";

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@material-ui/core";
import React from "react";
import { useStores } from "../../stores";

interface IProps {
  history: any;
}

export const Dashboard = (props: IProps) => {
  const { appStore } = useStores();

  React.useEffect(() => {}, [appStore]);

  return (
    <Layout history={props.history} module="dashboard" page="index">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="BEESHOPIFY DASHBOARD"></CardHeader>
            <Divider />
            <CardContent>
              Willkommen!
            </CardContent>
          </Card>
        </Grid>
    
      </Grid>
    </Layout>
  );
};
