import * as React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../../components/Layout";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  ButtonGroup,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import { DaskStore } from "../dask.store";
import useDrivePicker from "react-google-drive-picker";
import _ from "lodash";

import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FolderIcon from "@material-ui/icons/Folder";
import TreeItem from "@material-ui/lab/TreeItem";
import { toJS } from "mobx";

const unflatten = function (array: any, parent?: any, tree?: any) {
  tree = typeof tree !== "undefined" ? tree : [];
  parent =
    typeof parent !== "undefined" ? parent : { id: 0, level: 0, path: [] };

  var children = _.filter(array, function (child) {
    return child.parentId == parent.id;
  });

  if (!_.isEmpty(children)) {
    if (parent.id == 0) {
      tree = children;
    } else {
      parent["children"] = children;
    }

    _.each(children, function (child) {
      child.level = parent.level + 1;
      child.path = [...parent.path, `${child.id}`];
      unflatten(array, child, undefined);
    });
  }

  return tree;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    left: "calc(50% - 300px)",
    top: "20%",
  },
}));

interface IProps {
  history: any;
}

const store = new DaskStore();

const Folder = observer((props: any) => {
  const { data } = props;

  return data ? (
    <React.Fragment>
      {data.map((x: any) => (
        <TreeItem
          key={x.id}
          nodeId={`${x.id}`}
          label={store.showIds ? `${x.id} - ${x.name}` : x.name}
          onClick={(e) => {
            store.setActiveFolder(e, x);
            props.onSelected(x);
          }}
          icon={x.children ? undefined : <FolderIcon />}
        >
          <Folder data={x.children} onSelected={props.onSelected} />
        </TreeItem>
      ))}
    </React.Fragment>
  ) : null;
});

const File = observer((props: any) => {
  if (!props.data) return <React.Fragment />;
  let data = JSON.parse(props.data);

  return (
    <div
      className="folder"
      style={{
        padding: 5,
        margin: 5,
        border: "1px solid #ccc",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        wordBreak: "break-all",
      }}
      onClick={(e) => {
        props?.onSelected(data);
      }}
    >
      <img src={data.iconUrl} />
      <span style={{ marginLeft: 10 }}>
        {store.showIds ? `${props.id} - ${data.name}` : data.name}
      </span>
    </div>
  );
});

export const DaskDashboardPage = observer((props: IProps) => {
  const classes = useStyles();
  const [openPicker, authResponse] = useDrivePicker();
  const [token, setToken] = React.useState("");
  const [embedUrl, setEmbedUrl] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [name, setName] = React.useState("");
  const [icon, setIcon] = React.useState("");

  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);

  const [data, setData] = React.useState<any[]>([]);

  const fetch = async () => {
    let response = await store.fetchList();
    let result = unflatten(response);
    setData(result);
  };

  const handleDriveFolder = async () => {
    console.log("drive folder");
  };

  const swichtIds = () => {
    store.showIds = !store.showIds;
  };

  const handleOpenPicker = async () => {
    openPicker({
      clientId:
        "733366656784-8uro7s9s68ku0qt6kl3rufpvb2vc40ia.apps.googleusercontent.com",
      developerKey: "AIzaSyAoXab9xcLrd0Q8v1QeJIR4I_7gn1pWEd0",
      // viewId: "FOLDERS",
      token: token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      setIncludeFolders: true,
      setParentFolder: "1CBhjg7AvTmkN_-un4uDIJzhqVpp7WFsQ",
      // customViews: customViewsArray, // custom view
      callbackFunction: async (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }

        if (data.action === "loaded" && authResponse) {
          let googlejson = {
            ...authResponse,
            created: new Date(),
          };

          localStorage.setItem("google:picker", JSON.stringify(googlejson));

          if (!token) {
            setToken(authResponse.access_token);
          }
        }

        if (data.action === "picked" && data.docs) {
          await store.addDocs(data.docs);

          await fetch();

          setExpanded([...toJS(store.activeFolder.path)]);

          console.log("expanded: ", expanded);
        }
      },
    });
  };

  const handleSelect = (event: any, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  const [showButton, setShowButton] = React.useState(false);

  React.useEffect(() => {
    const google_picker = localStorage.getItem("google:picker");

    const user = localStorage.getItem("crm:user");

    if (user) {
      let user_o = JSON.parse(user);
      if (
        user_o.email === "burak.serpici@uandu.de" ||
        user_o.email === "derya.ay@uandu.de"
      ) {
        setShowButton(true);
      }
    }

    // if (google_picker) {
    //   const google_picker_result = JSON.parse(google_picker);
    //   const created = new Date(google_picker_result.created);
    //   const now = new Date();

    //   if (
    //     (now.getTime() - created.getTime()) / 1000 <
    //     google_picker_result.expires_in
    //   ) {
    //     console.log(google_picker_result);
    //     setToken(google_picker_result.access_token);
    //   }
    // }

    fetch();
  }, []);

  return (
    <Layout module="drive-document" page="find" history={props.history}>
      <div style={{ display: "flex", flex: 1, height: "calc(100vh - 120px)" }}>
        <Paper style={{ flex: 1, padding: 10 }}>
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div style={{ display: "flex", flex: 1 }}>
              <div style={{ width: 200, overflow: "auto" }}>
                <ButtonGroup size="small" style={{ marginBottom: 25 }}>
                  <Button
                    onClick={(e) => {
                      store.activeFolder.id = 0;
                      store.page.modal.show = true;
                    }}
                  >
                    New Folder
                  </Button>
                  <Button onClick={() => fetch()}>Reload</Button>
                </ButtonGroup>

                <TreeView
                  className={classes.root}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  // onNodeSelect={handleSelect}
                  // expanded={expanded}
                  selected={selected}
                >
                  <Folder
                    data={data}
                    onSelected={(x: any) => setExpanded(x.path)}
                  />
                </TreeView>
              </div>
              <div style={{ flex: 1, height: "100%", overflow: "auto" }}>
                <Paper
                  style={{ flex: 1, height: "100%", padding: 10, margin: 10 }}
                >
                  {store.activeFolder.id && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ButtonGroup size="small">
                        <Button onClick={() => (store.page.modal.show = true)}>
                          New Folder
                        </Button>
                        <Button
                          onClick={async () => {
                            let x = await store.removeFolder(
                              store.activeFolder
                            );

                            if (x) fetch();
                          }}
                        >
                          Remove Folder
                        </Button>
                      </ButtonGroup>

                      <ButtonGroup size="small">
                        <Button onClick={() => handleOpenPicker()}>
                          Add Drive File
                        </Button>
                        <Button onClick={() => handleOpenPicker()}>
                          Add Drive Folder
                        </Button>
                        <Button
                          onClick={() => {
                            setEmbedUrl("");
                            setUrl("");
                            setName("");
                            setIcon("");
                          }}
                        >
                          Close Document
                        </Button>
                        {url && (
                          <Button onClick={() => window.open(url)}>
                            Open in new Tab
                          </Button>
                        )}
                      </ButtonGroup>
                    </div>
                  )}

                  <div className="folder-content">
                    <div>
                      <h3>{store.activeFolder.name}</h3>
                    </div>

                    {store.activeFolder.children && (
                      <React.Fragment>
                        <div>
                          <strong>Folders:</strong>
                        </div>
                        <div
                          className="folder-grid"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                          }}
                        >
                          {toJS(store.activeFolder.children).map(
                            (child: any, i: number) => (
                              <div
                                className="folder"
                                key={i}
                                style={{
                                  padding: 5,
                                  margin: 5,
                                  border: "1px solid #ccc",
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  wordBreak: "break-all",
                                }}
                                onClick={(e) => {
                                  store.setActiveFolder(e, child);
                                  setExpanded(child.path);
                                  setSelected([`${child.id}`]);
                                }}
                              >
                                <FolderIcon />
                                <span style={{ marginLeft: 10 }}>
                                  {store.showIds ? `${child.id} - ${child.name}` : child.name}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </React.Fragment>
                    )}

                    {store.activeFolder.drive_documents &&
                      store.activeFolder.drive_documents.length > 0 && (
                        <React.Fragment>
                          <div style={{ marginTop: 15 }}>
                            <strong>Files:</strong>
                          </div>

                          <div
                            className="folder-grid"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(3, 1fr)",
                            }}
                          >
                            {store.activeFolder.drive_documents.map(
                              (doc: any, i: number) => (
                                <File
                                  key={i}
                                  id={doc.id}
                                  data={doc.data}
                                  onSelected={(d: any) => {
                                    setEmbedUrl(d.embedUrl);
                                    setUrl(d.url);
                                    setName(d.name);
                                    setIcon(d.iconUrl);
                                  }}
                                />
                              )
                            )}
                          </div>
                        </React.Fragment>
                      )}
                  </div>
                </Paper>
              </div>
            </div>

            <div style={{ flex: 1 }}>
              {embedUrl && (
                <iframe
                  onLoad={(e) => console.log("iframe on load")}
                  src={embedUrl}
                  style={{ width: "100%", height: "100%", border: 0 }}
                ></iframe>
              )}
            </div>
          </div>
        </Paper>
      </div>

      {showButton && (
        <div>
          <button onClick={swichtIds}>o</button>
        </div>
      )}

      <Modal
        open={store.page.modal.show}
        onClose={(e) => (store.page.modal.show = false)}
      >
        <div className={classes.modal}>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              label="Folder Name"
              fullWidth
              onChange={(e: any) => (store.data.model.name = e.target.value)}
              value={store.data.model.name || ""}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={async () => {
                  await store.newFolder();
                  await fetch();
                }}
                color="primary"
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </Layout>
  );
});
