import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import { Navigation } from "./Navigation";
import { Loading } from "./Loading";
import { useStores } from "../stores";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      height: "100vh",
    },
    title: {
      flexGrow: 1,
    },
  })
);

interface Props {
  module: string;
  page: string;
  window?: () => Window;
  history: any;
  children: React.ReactNode;
  loading?: boolean;
  public?: boolean;
}

export const Layout = observer((props: Props) => {
  const classes = useStyles();
  const { appStore } = useStores();
  const [fetching, setFetching] = React.useState(true);

  const checkRole = async () => {
    await appStore.checkRole(props.public);
    setFetching(false);
  };

  React.useEffect(() => {
    console.log("Layout: ", props.module, props.page);
    appStore.checkUser();
    appStore.setMenu(`${props.module}.${props.page}`);
    checkRole();

    if (!appStore.user.token) props.history.push("/auth/login");
  }, [appStore, props.history]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={appStore.setMenuOpen}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            BEE HAPPY!
          </Typography>

          <IconButton color="inherit">
            <span style={{ fontSize: 11, marginRight: 10 }}>
              {appStore.user.email}
            </span>
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Navigation window={window} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.loading || fetching ? (
          <Loading />
        ) : appStore.role_allowed ? (
          props.children
        ) : (
          <div>
            You are not authorized to view this module. Please contact to system
            administrator.
          </div>
        )}
      </main>
    </div>
  );
});
