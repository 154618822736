import * as React from "react";
import { observer } from "mobx-react";
import { Layout } from "../../../components/Layout";
import { useStores } from "../../../stores";
import { ExpenseStore } from "../expense.store";
import {
  TextField,
  makeStyles,
  Paper,
  Select,
  MenuItem,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { HelperService } from "../../../services/helper.service";

const expenseStore = new ExpenseStore();

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    left: "calc(50% - 300px)",
    top: "20%",
  },
}));

interface IProps {
  history: any;
  match?: any;
}

export const ExpenseDetailPage = observer((props: IProps) => {
  const { appStore } = useStores();
  const classes = useStyles();
  const [isEdit, setIsEdit] = React.useState(false);
  const [mode, setMode] = React.useState("");

  React.useEffect(() => {
    expenseStore.init();
    expenseStore.fetchLookups();

    const { id } = props.match.params;

    if (id) {
      setIsEdit(true);
      try {
        expenseStore.fetchRoles(id);
        expenseStore.fetch(id);
      } catch (e) {
        console.log(e);
        props.history.push("/expense");
      }
    } else {
      expenseStore.data.roles.edit = true;
      expenseStore.page.loading = false;
    }
  }, [appStore, props.match.params]);

  const onSave = async () => {
    const result = await expenseStore.save();
    props.history.push(`/expense/${result.id}/detail`);
  };

  const onControl = async () => {
    setMode("control");
    expenseStore.page.confirmationModal.show = true;
  };

  const onApprove = async () => {
    setMode("approve");
    expenseStore.page.confirmationModal.show = true;
  };

  const onReject = async () => {
    setMode("reject");
    expenseStore.page.confirmationModal.show = true;
  };

  const onPay = async () => {
    setMode("pay");
    expenseStore.page.confirmationModal.show = true;
  };

  const onComplete = async () => {
    setMode("complete");
    expenseStore.page.confirmationModal.show = true;
  };

  const onCancel = async () => {
    setMode("cancel");
    expenseStore.page.confirmationModal.show = true;
  };

  const onRemove = async () => {
    setMode("remove");
    expenseStore.page.confirmationModal.show = true;
  };

  const onDialogClose = () => {
    if (expenseStore.page.modal.success) {
      props.history.push(`/expense/${expenseStore.data.model.id}/detail`);
    }
  };

  const onActionConfirm = async () => {
    let success = false;

    switch (mode) {
      case "approve":
        success = await expenseStore.approve();
        break;
      case "control":
        success = await expenseStore.control();
        break;
      case "reject":
        success = await expenseStore.reject();
        break;
      case "pay":
        success = await expenseStore.pay();
        break;
      case "complete":
        success = await expenseStore.complete();
        break;
      case "cancel":
        success = await expenseStore.cancel();
        break;
      case "remove":
        success = await expenseStore.remove();
        if (success) props.history.push(`/expense/`);
        break;
    }

    if (success) {
      expenseStore.page.modal.show = false;
      // props.history.push(`/expense/`);

      window.location.reload();
    }
  };

  const { model } = expenseStore.data as any;

  if (model) {
    document.title = model.name;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Layout
        module="bill"
        page="create"
        history={props.history}
        loading={expenseStore.page.loading}
      >
        <Paper
          variant="outlined"
          elevation={1}
          style={{
            padding: 15,
            marginBottom: 15,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 style={{ margin: 0 }}>
            Expense Form{" "}
            {expenseStore.data.model.id && (
              <span>
                (#{expenseStore.data.model.referenceNo} -{" "}
                {expenseStore.data.model.status})
              </span>
            )}
          </h3>
          <ButtonGroup color="primary">
            <Button
              onClick={() => {
                props.history.push("/expense/me");
              }}
            >
              Back
            </Button>
            {/* <Button onClick={onRemove}>Remove</Button> */}
            {HelperService.checkObject(expenseStore.data.roles, "cancel") && (
              <Button onClick={onCancel}>Cancel</Button>
            )}
            {HelperService.checkObject(expenseStore.data.roles, "control") && (
              <Button onClick={onControl}>Control</Button>
            )}
            {HelperService.checkObject(expenseStore.data.roles, "approve") && (
              <Button onClick={onApprove}>Approved</Button>
            )}
            {HelperService.checkObject(expenseStore.data.roles, "approve") && (
              <Button onClick={onReject}>On Hold</Button>
            )}
            {HelperService.checkObject(expenseStore.data.roles, "payment") && (
              <Button onClick={onPay}>Paid</Button>
            )}
            {HelperService.checkObject(expenseStore.data.roles, "complete") && (
              <Button onClick={onComplete}>Complete</Button>
            )}
            {HelperService.checkObject(expenseStore.data.roles, "edit") && (
              <Button onClick={onSave}>Create</Button>
            )}
          </ButtonGroup>
        </Paper>

        <Paper
          variant="outlined"
          elevation={1}
          style={{ width: "100%", padding: 15 }}
        >
          <form className={classes.root} noValidate autoComplete="off">
            {isEdit && (
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <TextField
                    disabled
                    label="Reference No"
                    value={model.referenceNo || ""}
                    fullWidth
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    disabled
                    label="Owner"
                    value={(model.owner && model.owner.fullname) || ""}
                    fullWidth
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    disabled
                    label="Approver"
                    value={(model.manager && model.manager.fullname) || ""}
                    fullWidth
                  />
                </Grid>
                <Grid item md={3}>
                  <KeyboardDatePicker
                    disabled={!expenseStore.data.roles.approve}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Approval Date"
                    value={model.approvalDate}
                    onChange={(date) => (model.approvalDate = date)}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container spacing={3}>
              <Grid item md={6}>
                <TextField
                  disabled={!expenseStore.data.roles.edit}
                  label="Name"
                  value={model.name || ""}
                  onChange={(e) => (model.name = e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Company</InputLabel>
                  <Select
                    disabled={!expenseStore.data.roles.edit}
                    value={(model.company && model.company.id) || ""}
                    onChange={(e) => {
                      model.company.id = e.target.value;
                    }}
                  >
                    {expenseStore.data.lookup.companies.map(
                      (company: any, i: number) => (
                        <MenuItem value={company.id} key={i}>
                          {company.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    disabled={!expenseStore.data.roles.edit}
                    value={(model.type && model.type.id) || ""}
                    onChange={(e) => {
                      model.type.id = e.target.value;
                    }}
                  >
                    {expenseStore.data.lookup.types.map(
                      (type: any, i: number) => (
                        <MenuItem value={type.id} key={i}>
                          {type.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    disabled={!expenseStore.data.roles.edit}
                    value={(model.category && model.category.id) || ""}
                    onChange={(e) => {
                      model.category.id = e.target.value;
                    }}
                  >
                    {expenseStore.data.lookup.categories.map(
                      (type: any, i: number) => (
                        <MenuItem value={type.id} key={i}>
                          {type.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Sub Category</InputLabel>
                  <Select
                    disabled={!expenseStore.data.roles.edit}
                    value={(model.sub_category && model.sub_category.id) || ""}
                    onChange={(e) => {
                      model.sub_category.id = e.target.value;
                    }}
                  >
                    {expenseStore.data.lookup.subcategories.map(
                      (type: any, i: number) => (
                        <MenuItem value={type.id} key={i}>
                          {type.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={3}>
                <KeyboardDatePicker
                  disabled={!expenseStore.data.roles.edit}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Date"
                  value={model.billDate}
                  onChange={(date) => (model.billDate = date)}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  disabled={!expenseStore.data.roles.edit}
                  fullWidth
                  label="Number"
                  value={model.billNumber || ""}
                  onChange={(e) => (model.billNumber = e.target.value)}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  fullWidth
                  disabled={!expenseStore.data.roles.edit}
                  label="Amount"
                  type="number"
                  value={model.totalAmount}
                  onChange={(e) => (model.totalAmount = +e.target.value)}
                />
              </Grid>
              <Grid item md={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Currency</InputLabel>
                  <Select
                    disabled={!expenseStore.data.roles.edit}
                    value={model.currency || ""}
                    onChange={(e) => {
                      model.currency = e.target.value;
                    }}
                  >
                    <MenuItem value={"EUR"}>EUR</MenuItem>
                    <MenuItem value={"USD"}>USD</MenuItem>
                    <MenuItem value={"TRY"}>TRY</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={3}>
                <TextField
                  disabled={!expenseStore.data.roles.edit}
                  fullWidth
                  label="Tax"
                  type="number"
                  value={model.tax}
                  onChange={(e) => (model.tax = +e.target.value)}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  disabled={!expenseStore.data.roles.edit}
                  fullWidth
                  label="Paid Amount"
                  type="number"
                  value={model.paidAmount}
                  onChange={(e) => (model.paidAmount = e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <TextField
                  disabled={!expenseStore.data.roles.edit}
                  label="IBAN Note"
                  value={model.iban || ""}
                  onChange={(e) => (model.iban = e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  disabled={!expenseStore.data.roles.edit}
                  multiline
                  label="Description"
                  value={model.description || ""}
                  onChange={(e) => (model.description = e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Divider style={{ marginTop: 25, marginBottom: 25 }} />

            <Grid container spacing={3}>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  component="label"
                  disabled={!expenseStore.data.roles.edit}
                >
                  Upload File 1
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      model.attachment = e.target.files
                        ? e.target.files[0]
                        : undefined;
                    }}
                  />
                </Button>
                <div style={{ marginTop: 15 }}>
                  {model.attachment && model.attachment.name}
                </div>

                <div style={{ marginTop: 15 }}>
                  {model.attachment && model.attachment.url && (
                    <a
                      href={`${process.env.REACT_APP_API_URL}${model.attachment.url}`}
                      target="_blank"
                    >
                      {model.attachment.name}
                    </a>
                  )}
                </div>
              </Grid>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  component="label"
                  disabled={!expenseStore.data.roles.edit}
                >
                  Upload File 2
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      model.attachment2 = e.target.files
                        ? e.target.files[0]
                        : undefined;
                    }}
                  />
                </Button>
                <div style={{ marginTop: 15 }}>
                  {model.attachment2 && model.attachment2.name}
                </div>

                <div style={{ marginTop: 15 }}>
                  {model.attachment2 && model.attachment2.url && (
                    <a
                      href={`${process.env.REACT_APP_API_URL}${model.attachment2.url}`}
                      target="_blank"
                    >
                      {model.attachment2.name}
                    </a>
                  )}
                </div>
              </Grid>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  component="label"
                  disabled={!expenseStore.data.roles.edit}
                >
                  Upload File 3
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      model.attachment3 = e.target.files
                        ? e.target.files[0]
                        : undefined;
                    }}
                  />
                </Button>
                <div style={{ marginTop: 15 }}>
                  {model.attachment3 && model.attachment3.name}
                </div>

                <div style={{ marginTop: 15 }}>
                  {model.attachment3 && model.attachment3.url && (
                    <a
                      href={`${process.env.REACT_APP_API_URL}${model.attachment3.url}`}
                      target="_blank"
                    >
                      {model.attachment3.name}
                    </a>
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        </Paper>

        {model.id && (
          <React.Fragment>
            <Paper
              variant="outlined"
              elevation={1}
              style={{ width: "100%", padding: 15, marginTop: 15 }}
            >
              <Paper
                variant="outlined"
                elevation={1}
                style={{
                  padding: 15,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3 style={{ margin: 0 }}>Payments</h3>
                <ButtonGroup
                  color="primary"
                  // disabled={!expenseStore.data.roles.payment}
                >
                  <Button onClick={expenseStore.paymentAdd}>Add Payment</Button>
                </ButtonGroup>
              </Paper>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Bank</TableCell>
                      <TableCell>Channel</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Iban</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {model.payments &&
                      model.payments.map((payment: any, i: number) => (
                        <TableRow key={i}>
                          <TableCell>
                            {moment(payment.paymentDate).format("LL")}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {payment.bank}
                          </TableCell>
                          <TableCell>
                            {payment.payment_channel
                              ? payment.payment_channel.title
                              : ""}
                          </TableCell>
                          <TableCell>
                            {payment.amount} {payment.currency}
                          </TableCell>
                          <TableCell>{payment.iban}</TableCell>
                          <TableCell align="right">
                            <Button
                              disabled={false && !expenseStore.data.roles.edit}
                              onClick={(e) =>
                                expenseStore.paymentUpdate(payment)
                              }
                            >
                              Update
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Paper
              variant="outlined"
              elevation={1}
              style={{
                padding: 15,
                marginTop: 15,
                marginBottom: 15,
              }}
            >
              <h3>History</h3>

              <div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Activity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {model.histories &&
                        model.histories.map((history: any, i: number) => (
                          <TableRow key={i}>
                            <TableCell>
                              {moment(history.created_at).format(
                                "DD/MM/YYYY, HH:mm:ss"
                              )}
                            </TableCell>
                            <TableCell>{history.activity}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Paper>
          </React.Fragment>
        )}
      </Layout>

      <Modal
        open={expenseStore.page.paymentModal}
        onClose={(e) => (expenseStore.page.paymentModal = false)}
      >
        <div className={classes.modal}>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              label="Bank"
              disabled
              fullWidth
              onChange={(e) =>
                (expenseStore.data.payment.bank = e.target.value)
              }
              value={expenseStore.data.payment.bank || ""}
            />

            {expenseStore.data.lookup.channels &&
              expenseStore.data.lookup.channels.length > 0 && (
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Payment Channel</InputLabel>
                  <Select
                    value={
                      (expenseStore.data.payment.payment_channel &&
                        expenseStore.data.payment.payment_channel.id) ||
                      ""
                    }
                    onChange={(e) => {
                      expenseStore.data.payment.payment_channel.id =
                        e.target.value;
                    }}
                  >
                    {expenseStore.data.lookup.channels.map(
                      (type: any, i: number) => (
                        <MenuItem value={type.id} key={i}>
                          {type.title}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}

            <TextField
              label="IBAN"
              fullWidth
              onChange={(e) =>
                (expenseStore.data.payment.iban = e.target.value)
              }
              value={expenseStore.data.payment.iban || ""}
            />
            <TextField
              label="Amount"
              type="number"
              fullWidth
              onChange={(e) =>
                (expenseStore.data.payment.amount = +e.target.value)
              }
              value={expenseStore.data.payment.amount}
            />
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel>Currency</InputLabel>
              <Select
                value={expenseStore.data.payment.currency || ""}
                onChange={(e) => {
                  expenseStore.data.payment.currency = e.target.value;
                }}
              >
                <MenuItem value={"EUR"}>EUR</MenuItem>
                <MenuItem value={"USD"}>USD</MenuItem>
                <MenuItem value={"TRY"}>TRY</MenuItem>
              </Select>
            </FormControl>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Date"
              value={expenseStore.data.payment.paymentDate}
              onChange={(date) =>
                (expenseStore.data.payment.paymentDate = date)
              }
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={expenseStore.savePayment} color="primary">
                Save
              </Button>
            </div>
          </form>
        </div>
      </Modal>

      <Dialog
        open={expenseStore.page.confirmationModal.show}
        onClose={onDialogClose}
      >
        <DialogTitle>{expenseStore.page.confirmationModal.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {expenseStore.page.confirmationModal.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={expenseStore.page.confirmationModal.show}
        onClose={(e) => (expenseStore.page.confirmationModal.show = false)}
      >
        <DialogTitle>{expenseStore.page.confirmationModal.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {expenseStore.page.confirmationModal.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => (expenseStore.page.confirmationModal.show = false)}
            color="primary"
          >
            NO
          </Button>
          <Button onClick={onActionConfirm} color="primary">
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
});
