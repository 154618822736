import * as React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { Layout } from "../../../components/Layout";
import { useStores } from "../../../stores";
import { ExpenseStore } from "../expense.store";
import { IHeaderProps, SortableTable } from "../../../components/SortableTable";
import { HelperService } from "../../../services/helper.service";

interface IProps {
  history: any;
}

const expenseStore = new ExpenseStore();

var headers: IHeaderProps[] = [
  {
    name: "created_at",
    text: "Created",
    type: "date",
    sortable: true,
  },
  {
    name: "billDate",
    text: "Date",
    type: "date",
    sortable: true,
  },
  {
    name: "referenceNo",
    text: "Ref#",
    sortable: true,
  },
  {
    name: "billNumber",
    text: "Number",
    sortable: true,
  },
  {
    name: "owner.fullname",
    text: "Owner",
    sortable: true,
  },
  {
    name: "name",
    text: "Name",
    sortable: true,
  },
  {
    name: "company.name",
    text: "Company",
    sortable: true,
  },
  {
    name: "totalAmount",
    text: "Total",
    sortable: true,
    render: (data: any) => (
      <span>
        {HelperService.currencyFormat(data.totalAmount, data.currency)}
      </span>
    ),
  },
];

export const ExpenseControlPage = observer((props: IProps) => {
  const { appStore } = useStores();

  React.useEffect(() => {
    expenseStore.init();
  }, [appStore]);

  return (
    <Layout module="bill" page="control" history={props.history}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SortableTable
            endpoint="/bills/control"
            headers={headers}
            onEditClicked={(id) => {
              props.history.push(`/expense/${id}/detail`);
            }}
            sort="created_at"
          />
        </Grid>
      </Grid>
    </Layout>
  );
});
