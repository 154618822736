import * as React from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Switch,
  FormControlLabel,
  TableContainer,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { Layout } from "../../../components/Layout";
import { useStores } from "../../../stores";
import { ExpenseStore } from "../expense.store";
import { HelperService } from "../../../services/helper.service";
import { toJS } from "mobx";
import { Loading } from "../../../components/Loading";
import EditIcon from "@material-ui/icons/Visibility";

interface IProps {
  history: any;
}

const expenseStore = new ExpenseStore();

export const ExpenseSummaryPage = observer((props: IProps) => {
  const { appStore } = useStores();
  const classes = useStyles();

  const [details, setDetails] = React.useState({ value: 0, data: [] });
  const [detailModal, setDetailModal] = React.useState(false);
  const [detailModalHeader, setDetailModalHeader] = React.useState("");
  const [startDate, setStartDate] = React.useState(undefined);
  const [endDate, setEndDate] = React.useState(undefined);
  const [showTax, setShowTax] = React.useState(false);

  const init = async () => {
    await expenseStore.fetchSummary({ start: startDate, end: endDate });
    console.log(toJS(expenseStore.data.summary));
  };

  React.useEffect(() => {
    init();
  }, [appStore]);

  if (expenseStore.page.loading) return <Loading />;

  return (
    <Layout module="bill" page="pivot" history={props.history}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: 25 }}>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <InputLabel>Start Date</InputLabel>
                <Select
                  fullWidth
                  onChange={(e: any) => setStartDate(e.target.value)}
                >
                  <MenuItem value={"2020-01-01"}>01-2020</MenuItem>
                  <MenuItem value={"2020-02-01"}>02-2020</MenuItem>
                  <MenuItem value={"2020-03-01"}>03-2020</MenuItem>
                  <MenuItem value={"2020-04-01"}>04-2020</MenuItem>
                  <MenuItem value={"2020-05-01"}>05-2020</MenuItem>
                  <MenuItem value={"2020-06-01"}>06-2020</MenuItem>
                  <MenuItem value={"2020-07-01"}>07-2020</MenuItem>
                  <MenuItem value={"2020-08-01"}>08-2020</MenuItem>
                  <MenuItem value={"2020-09-01"}>09-2020</MenuItem>
                  <MenuItem value={"2020-10-01"}>10-2020</MenuItem>
                  <MenuItem value={"2020-11-01"}>11-2020</MenuItem>
                  <MenuItem value={"2020-12-01"}>12-2020</MenuItem>
                  <MenuItem value={"2021-01-01"}>01-2021</MenuItem>
                  <MenuItem value={"2021-02-01"}>02-2021</MenuItem>
                  <MenuItem value={"2021-03-01"}>03-2021</MenuItem>
                  <MenuItem value={"2021-04-01"}>04-2021</MenuItem>
                  <MenuItem value={"2021-05-01"}>05-2021</MenuItem>
                  <MenuItem value={"2021-06-01"}>06-2021</MenuItem>
                  <MenuItem value={"2021-07-01"}>07-2021</MenuItem>
                  <MenuItem value={"2021-08-01"}>08-2021</MenuItem>
                  <MenuItem value={"2021-09-01"}>09-2021</MenuItem>
                  <MenuItem value={"2021-10-01"}>10-2021</MenuItem>
                  <MenuItem value={"2021-11-01"}>11-2021</MenuItem>
                  <MenuItem value={"2021-12-01"}>12-2021</MenuItem>
                  <MenuItem value={"2022-01-01"}>01-2022</MenuItem>
                  <MenuItem value={"2022-02-01"}>02-2022</MenuItem>
                  <MenuItem value={"2022-03-01"}>03-2022</MenuItem>
                  <MenuItem value={"2022-04-01"}>04-2022</MenuItem>
                  <MenuItem value={"2022-05-01"}>05-2022</MenuItem>
                  <MenuItem value={"2022-06-01"}>06-2022</MenuItem>
                  <MenuItem value={"2022-07-01"}>07-2022</MenuItem>
                  <MenuItem value={"2022-08-01"}>08-2022</MenuItem>
                  <MenuItem value={"2022-09-01"}>09-2022</MenuItem>
                  <MenuItem value={"2022-10-01"}>10-2022</MenuItem>
                  <MenuItem value={"2022-11-01"}>11-2022</MenuItem>
                  <MenuItem value={"2022-12-01"}>12-2022</MenuItem>
                </Select>
              </Grid>
              <Grid item sm={2}>
                <InputLabel>End Date</InputLabel>
                <Select
                  fullWidth
                  onChange={(e: any) => setEndDate(e.target.value)}
                >
                  <MenuItem value={"2020-02-01"}>01-2020</MenuItem>
                  <MenuItem value={"2020-03-01"}>02-2020</MenuItem>
                  <MenuItem value={"2020-04-01"}>03-2020</MenuItem>
                  <MenuItem value={"2020-05-01"}>04-2020</MenuItem>
                  <MenuItem value={"2020-06-01"}>05-2020</MenuItem>
                  <MenuItem value={"2020-07-01"}>06-2020</MenuItem>
                  <MenuItem value={"2020-08-01"}>07-2020</MenuItem>
                  <MenuItem value={"2020-09-01"}>08-2020</MenuItem>
                  <MenuItem value={"2020-10-01"}>09-2020</MenuItem>
                  <MenuItem value={"2020-11-01"}>10-2020</MenuItem>
                  <MenuItem value={"2020-12-01"}>11-2020</MenuItem>
                  <MenuItem value={"2022-01-01"}>12-2020</MenuItem>
                  <MenuItem value={"2021-02-01"}>01-2021</MenuItem>
                  <MenuItem value={"2021-03-01"}>02-2021</MenuItem>
                  <MenuItem value={"2021-04-01"}>03-2021</MenuItem>
                  <MenuItem value={"2021-05-01"}>04-2021</MenuItem>
                  <MenuItem value={"2021-06-01"}>05-2021</MenuItem>
                  <MenuItem value={"2021-07-01"}>06-2021</MenuItem>
                  <MenuItem value={"2021-08-01"}>07-2021</MenuItem>
                  <MenuItem value={"2021-09-01"}>08-2021</MenuItem>
                  <MenuItem value={"2021-10-01"}>09-2021</MenuItem>
                  <MenuItem value={"2021-11-01"}>10-2021</MenuItem>
                  <MenuItem value={"2021-12-01"}>11-2021</MenuItem>
                  <MenuItem value={"2022-01-01"}>12-2021</MenuItem>
                  <MenuItem value={"2022-02-01"}>01-2022</MenuItem>
                  <MenuItem value={"2022-03-01"}>02-2022</MenuItem>
                  <MenuItem value={"2022-04-01"}>03-2022</MenuItem>
                  <MenuItem value={"2022-05-01"}>04-2022</MenuItem>
                  <MenuItem value={"2022-06-01"}>05-2022</MenuItem>
                  <MenuItem value={"2022-07-01"}>06-2022</MenuItem>
                  <MenuItem value={"2022-08-01"}>07-2022</MenuItem>
                  <MenuItem value={"2022-09-01"}>08-2022</MenuItem>
                  <MenuItem value={"2022-10-01"}>09-2022</MenuItem>
                  <MenuItem value={"2022-11-01"}>10-2022</MenuItem>
                  <MenuItem value={"2022-12-01"}>11-2022</MenuItem>
                  <MenuItem value={"2023-01-01"}>12-2022</MenuItem>
                </Select>
              </Grid>

              <Grid item sm={2}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e: any) => setShowTax(e.target.checked)}
                      name="notax"
                    />
                  }
                  label="Show with Taxes"
                />
              </Grid>

              <Grid item sm={2}>
                <Button title="Search" onClick={async (e: any) => await init()}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <h2>Consolidated Report</h2>
          <TableContainer style={{ height: 600 }} component={Paper}>
            <Table style={{ width: "100%" }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell component="th"></TableCell>

                  {expenseStore.data.summary.meta.cols.map(
                    (c: any, i: number) => (
                      <TableCell component="th" align="right" key={i}>
                        {c}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {expenseStore.data.summary.meta.rows.map(
                  (row: any, j: number) => (
                    <TableRow key={j}>
                      <TableCell>{row}</TableCell>

                      {expenseStore.data.summary.meta.cols.map(
                        (col: any, k: number) => (
                          <TableCell align="right" key={k}>
                            <a
                              href="#"
                              onClick={(e) => {
                                if (
                                  expenseStore.data.summary.data[row] &&
                                  expenseStore.data.summary.data[row][col]
                                ) {
                                  setDetails(
                                    expenseStore.data.summary.data[row][col]
                                  );

                                  setDetailModal(true);
                                  setDetailModalHeader(`${row} / ${col}`);
                                }
                              }}
                              style={{
                                color: "#000",
                                textDecoration: "none",
                              }}
                            >
                              {expenseStore.data.summary.data[row] &&
                              expenseStore.data.summary.data[row][col]
                                ? showTax
                                  ? HelperService.currencyFormat(
                                      expenseStore.data.summary.data[row][col]
                                        .value,
                                      "EUR"
                                    )
                                  : HelperService.currencyFormat(
                                      expenseStore.data.summary.data[row][col]
                                        .valueNoTax,
                                      "EUR"
                                    )
                                : ""}
                            </a>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {expenseStore.data.summary.companyData.map(
          (company: any, i: number) => (
            <Grid item xs={12}>
              <Paper style={{ padding: 25 }}>
                <h2>{company.name}</h2>

                <TableContainer style={{ height: 600 }}>
                  <Table style={{ width: "100%" }} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell component="th"></TableCell>

                        {company.meta.cols.map((c: any, i: number) => (
                          <TableCell component="th" align="right" key={i}>
                            {c}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {company.meta.rows.map((row: any, j: number) => (
                        <TableRow key={j}>
                          <TableCell>{row}</TableCell>

                          {company.meta.cols.map((col: any, k: number) => (
                            <TableCell align="right" key={k}>
                              <a
                                href="#"
                                onClick={(e) => {
                                  if (
                                    expenseStore.data.summary.data[row] &&
                                    expenseStore.data.summary.data[row][col]
                                  ) {
                                    setDetails(company.data[row][col]);

                                    setDetailModal(true);
                                    setDetailModalHeader(`${row} / ${col}`);
                                  }
                                }}
                                style={{
                                  color: "#000",
                                  textDecoration: "none",
                                }}
                              >
                                {company.data[row][col]
                                  ? HelperService.currencyFormat(
                                      company.data[row][col].value,
                                      "EUR"
                                    )
                                  : ""}
                              </a>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          )
        )}
      </Grid>

      <Modal
        open={detailModal}
        onClose={(e) => {
          setDetailModal(false);
        }}
      >
        <div className={classes.modal}>
          <div>
            <h2>{detailModalHeader}</h2>
            <h3>
              Total: {HelperService.currencyFormat(details.value, "EUR")}{" "}
            </h3>
          </div>
          <div style={{ flex: 1, overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th">Date</TableCell>
                  <TableCell component="th">Name</TableCell>
                  <TableCell component="th">Category</TableCell>
                  <TableCell component="th">Original Amount</TableCell>
                  <TableCell component="th">Currency</TableCell>
                  <TableCell component="th">Amount (EUR)</TableCell>
                  <TableCell component="th">Tax (EUR)</TableCell>
                  <TableCell component="th">Amount (EUR) with Tax</TableCell>
                  <TableCell component="th"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.data.map((detail: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell>{detail.paymentDate}</TableCell>
                    <TableCell>{detail.name}</TableCell>
                    <TableCell>{detail.category}</TableCell>
                    <TableCell>
                      {HelperService.currencyFormat(
                        detail.originalPrice,
                        detail.currency
                      )}
                    </TableCell>
                    <TableCell>{detail.currency}</TableCell>
                    <TableCell>
                      {HelperService.currencyFormat(
                        detail.totalAmountNoTax,
                        "EUR"
                      )}
                    </TableCell>
                    <TableCell>
                      {HelperService.currencyFormat(detail.tax, "EUR")}
                    </TableCell>
                    <TableCell>
                      {HelperService.currencyFormat(detail.totalAmount, "EUR")}
                    </TableCell>
                    <TableCell>
                      <ButtonGroup size="small" variant="text">
                        <Button
                          onClick={() =>
                            window.open(`/expense/${detail.id}/detail`)
                          }
                        >
                          <EditIcon />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </Modal>
    </Layout>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    position: "absolute",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    left: "10%",
    top: "20%",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
  },
}));
