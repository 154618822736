import * as React from "react";
import { Route, Switch } from "react-router";
import { DriveDashboardPage } from "./pages/drive.page.dashboard";

interface IProps {
  subpath?: string;
}

export const DriveRoutes = (props: IProps) => (
  <Switch>
    <Route path="/google-drive" exact component={DriveDashboardPage}></Route>
  </Switch>
);
