import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField
} from "@material-ui/core";
import { observer } from "mobx-react";
import { Layout } from "../../../components/Layout";
import { Loading } from "../../../components/Loading";
import { useStores } from "../../../stores";
import { AccountStore } from "../account.store";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface IProps {
  history: any;
}

const accountStore = new AccountStore();

export const AccountSettingsPage = observer((props: IProps) => {
  const { appStore } = useStores();

  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [message, setmessage] = React.useState<string | undefined>(undefined);
  const [ dialogOpen, setDialogOpen ] = React.useState(false);
  const [ isSuccess, setIsSuccess ] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  React.useEffect(() => {
    accountStore.init();

    setTimeout(() => {
      accountStore.page.loading = false;
    }, 1000);
  }, [appStore]);

  const onPasswordSubmit = async () => {
    if(!password || !password2) {
      setmessage("Please provide password and password confirmation");
      setDialogOpen(true);
      return;
    }

    if (password !== password2) {
      setmessage("Passwords are not same");
      setDialogOpen(true);
      return;
    }

    const result = await accountStore.change_password(password, password2);

    if(result) {
      setmessage("Password change is successful!");
      setIsSuccess(true);
      setDialogOpen(true);
    }
    else {
      setmessage("An message occured");
    }
  }

  const onDialogClose = () => {
    setDialogOpen(false);

    if(isSuccess) {
      appStore.logout();
    }
  }

  return (
    <Layout
      module="account"
      page="settings"
      history={props.history}
      loading={accountStore.page.loading}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Account Settings"></CardHeader>
            <Divider />
            <CardContent>
              <Button
                onClick={(e) =>
                  appStore.logout().then((r) => props.history.push("/"))
                }
              >
                Logout
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Change Password"></CardHeader>
            <Divider />
            <CardContent>
              <form>
                <Grid container spacing={3}>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={e => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="standard-adornment-password2">Password Confirmation</InputLabel>
                      <Input
                        id="standard-adornment-password2"
                        type={showPassword2 ? 'text' : 'password'}
                        value={password2}
                        onChange={e => setPassword2(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={e => setShowPassword2(!showPassword2)}
                            >
                              {showPassword2 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) =>
                        onPasswordSubmit()
                      }
                    >
                      Change
                    </Button>
                  </Grid>
                </Grid>
              </form>

            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={onDialogClose}
      >
        <DialogTitle>Message</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
});
