import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from "@material-ui/icons/Settings";
import LinkIcon from "@material-ui/icons/Link";
import SignoutIcon from "@material-ui/icons/ExitToApp";
import DriveIcon from "@material-ui/icons/DriveEta";

import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import { Collapse, ListSubheader } from "@material-ui/core";

import DashboardIcon from "@material-ui/icons/Dashboard";

import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import { ExpandLess, ExpandMore, StarBorder } from "@material-ui/icons";
import React from "react";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
  })
);

interface Props {
  window: Window;
  children?: React.ReactNode;
}

interface INavItemProps {
  to: string;
  text: string;
  selected?: boolean;
  icon?: any;
  nested?: boolean;
}

const NavItem = (props: INavItemProps) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      component={Link}
      to={props.to}
      selected={props.selected}
      className={props.nested ? classes.nested : ""}
    >
      <ListItemIcon>{props.icon && <props.icon />}</ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItem>
  );
};

interface IExpandableNavItemProps {
  text: string;
  icon: any;
  selected?: boolean;
  children: React.ReactNode;
}

const ExpandableNavItem = (props: IExpandableNavItemProps) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(props.selected!);
  }, [props.selected]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleClick} selected={props.selected}>
        <ListItemIcon>
          <props.icon />
        </ListItemIcon>
        <ListItemText primary={props.text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {props.children}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export const Navigation = observer((props: Props) => {
  const { window } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { appStore } = useStores();

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img src={Logo} width={50} alt="logo" />
      </div>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Apps
          </ListSubheader>
        }
      >
        <NavItem
          to="/"
          text="Dashboard"
          selected={appStore.menu === "dashboard"}
          icon={DashboardIcon}
        />

        <NavItem
          to="/google-drive"
          text="Google Drive"
          selected={appStore.menu === "drive"}
          icon={DriveIcon}
        />

        <NavItem
          to="/dont-ask-me"
          text="DASK!"
          selected={appStore.submenu === "dashboard"}
          icon={DriveIcon}
        />

        <ExpandableNavItem
          text="Expense"
          icon={LinkIcon}
          selected={appStore.menu === "bill"}
        >
          <NavItem
            nested
            to="/expense"
            text="Invoice Database"
            selected={appStore.submenu === "find"}
          />
          <NavItem
            nested
            to="/expense/control"
            text="Control Finance"
            selected={appStore.submenu === "control"}
          />
          <NavItem
            nested
            to="/expense/create"
            text="Invoice Create"
            selected={appStore.submenu === "create"}
          />
          <NavItem
            nested
            to="/expense/pending"
            text="Pending"
            selected={appStore.submenu === "pending"}
          />
          <NavItem
            nested
            to="/expense/history"
            text="History"
            selected={appStore.submenu === "history"}
          />
          <NavItem
            nested
            to="/expense/me"
            text="My List"
            selected={appStore.submenu === "me"}
          />
          <NavItem
            nested
            to="/expense/report"
            text="Reports"
            selected={appStore.submenu === "report"}
          />
          <NavItem
            nested
            to="/expense/summary"
            text="Summary"
            selected={appStore.submenu === "pivot"}
          />
          {/* <NavItem
            nested
            to="/expense/report/payment"
            text="Payments"
            selected={appStore.submenu === "payment_report"}
          /> */}
        </ExpandableNavItem>
        <ExpandableNavItem
          text="CX"
          icon={LinkIcon}
          selected={appStore.menu === "cx"}
        >
          <NavItem
            nested
            to="/customer-experience"
            text="Dashboard"
            selected={appStore.submenu === "dashboard"}
          />
          <NavItem
            nested
            to="/customer-experience/whatsapp"
            text="Whatsapp History"
            selected={appStore.submenu === "find"}
          />
        </ExpandableNavItem>
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Account
          </ListSubheader>
        }
      >
        <ListItem
          button
          component={Link}
          to="/account/settings"
          selected={appStore.menu === "account.settings"}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={"Settings"} />
        </ListItem>
        <ListItem
          button
          onClick={(e) =>
            appStore.logout().then((r) => window.location.reload())
          }
        >
          <ListItemIcon>
            <SignoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={appStore.menuOpen}
          onClose={appStore.setMenuOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
});
