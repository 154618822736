import axios from "axios";
import { action, makeAutoObservable, toJS } from "mobx";
import { api } from "../../services/api.service";

export class DriveStore {
  page = {
    loading: true,
    modal: {
      show: false,
      title: "",
      content: "",
      success: false,
    },
    confirmationModal: {
      show: false,
      title: "",
      content: "Are you sure?",
      success: false,
    },
  };

  data = {
    list: [],
    model: {
      id: undefined,
      title: "",
      url: "",
      isFolder: false,
      isRoot: false,
      children: [],
      parent: undefined,
    } as any,
    roles: {} as any,
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action init = () => {
    console.log("Initializing Google Drive Store");

    // Reset Data
    this.page = {
      loading: true,
      modal: {
        show: false,
        title: "",
        content: "",
        success: false,
      },
      confirmationModal: {
        show: false,
        title: "",
        content: "Are you sure?",
        success: false,
      },
    };

    this.data = {
      list: [],
      model: {
        id: undefined,
        title: "",
        url: "",
        isFolder: false,
        isRoot: false,
        children: [],
        parent: undefined,
      },
      roles: {},
    };
  };

  @action fetchLookups = async () => {};

  @action fetchList = async () => {
    const result = await api.get("/google-drives");

    this.data.list = result.data;

    console.log(toJS(this.data));
    this.page.loading = false;
  };

  @action fetch = async (id: number) => {
    const result = await api.get("/google-drives/" + id);
    this.data.model = result.data;
    this.page.loading = false;
  };

  @action save = async () => {
    this.page.loading = true;

    var formdata = new FormData();

    formdata.set("data", JSON.stringify(this.data.model));
    formdata.set("files.attachment", this.data.model.attachment);

    if (this.data.model.id) {
      const result = await api.put(
        "/google-drives/" + this.data.model.id,
        formdata
      );
      this.page.loading = false;

      this.page.modal.title = "Success";
      this.page.modal.content = "Form successfully saved";
      this.page.modal.show = true;
      this.page.modal.success = true;
      this.data.model = result.data;

      return result.data;
    } else {
      const result = await api.post("/google-drives", formdata);
      this.page.loading = false;

      this.page.modal.title = "Success";
      this.page.modal.content = "Form successfully saved";
      this.page.modal.show = true;
      this.page.modal.success = true;
      this.data.model = result.data;
      return result.data;
    }
  };
}
