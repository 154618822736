import axios from "axios";
import { action, makeAutoObservable, toJS } from "mobx";
import { api } from "../../services/api.service";

export class DaskStore {
  page = {
    loading: true,
    modal: {
      show: false,
      title: "",
      content: "",
      success: false,
    },
    confirmationModal: {
      show: false,
      title: "",
      content: "Are you sure?",
      success: false,
    },
  };

  data = {
    list: [],
    model: {} as any,
    roles: {} as any,
  };

  showIds = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action init = () => {
    console.log("Initializing Dask Store");

    // Reset Data
    this.page = {
      loading: true,
      modal: {
        show: false,
        title: "",
        content: "",
        success: false,
      },
      confirmationModal: {
        show: false,
        title: "",
        content: "Are you sure?",
        success: false,
      },
    };

    this.data = {
      list: [],
      model: {},
      roles: {},
    };
  };

  activeFolder = {} as any;
  activeDocument = {} as any;

  @action
  setActiveFolder = async (e: any, item: any) => {
    e.stopPropagation();
    this.activeFolder = item;
  };

  @action
  setActiveDocument = async (item: any) => {
    this.activeDocument = item;
  };

  @action
  newFile = async () => {
    console.log(toJS(this.activeFolder));
  };

  @action newFolder = async () => {
    const result = await api.post("drive-folders", {
      parentId: this.activeFolder.id,
      name: this.data.model.name,
    });

    this.data.model.name = "";
    this.page.modal.show = false;

    return result.data;
  };

  @action removeFolder = async (folder: any) => {
    let ans = window.confirm("Are you sure to delete folder");

    if (ans) {
      const result = await api.delete(`drive-folders/${folder.id}`);

      return result.data;
    }

    return false;
  };

  @action
  fetchLookups = async () => {};

  @action fetchList = async () => {
    const result = await api.get("drive-folders", {
      params: { _limit: 10000 },
    });

    this.data.list = result.data;
    this.page.loading = false;

    return result.data;
  };

  @action addDoc = async () => {
    const result = await api.post("drive-documents", {
      data: JSON.stringify(this.activeDocument),
      drive_folder: this.activeFolder.id,
    });

    return result.data;
  };

  @action addDocs = async (data: any, isFolder = false) => {
    data.forEach(async (d: any) => {
      const result = await api.post("drive-documents", {
        data: JSON.stringify(d),
        drive_folder: this.activeFolder.id,
      });
    });
  };

  @action fetch = async (id: number) => {};

  @action save = async () => {};
}
