import * as React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "../../../components/Layout";
import { makeStyles } from "@material-ui/core/styles";

import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FolderIcon from "@material-ui/icons/Folder";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import TreeItem from "@material-ui/lab/TreeItem";
import { Paper } from "@material-ui/core";
import { DriveStore } from "../drive.store";

const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
});

interface IProps {
  history: any;
}

const driveStore = new DriveStore();

export const DriveDashboardPage = observer((props: IProps) => {
  const classes = useStyles();

  const [source, setSource] = React.useState("");

  const changeSource = (s: string) => {
    setSource(s);
  };

  const createSource = () => {
    console.log("Show modal");
  };

  const init = async () => {
    await driveStore.fetchList();
  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <Layout module="google-drive" page="find" history={props.history}>
      <div style={{ display: "flex", flex: 1, height: "calc(100vh - 120px)" }}>
        <Paper style={{ width: 200, padding: 10, marginRight: 25 }}>
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {driveStore.data.list.map((root: any) => (
              <TreeItem
                key={root.id}
                nodeId={`${root.id}`}
                label={root.title}
                style={{ marginBottom: 10 }}
              >
                {root.children.map((child: any) => (
                  <TreeItem
                    key={child.id}
                    nodeId={`${child.id}`}
                    label={child.title}
                    icon={child.isFolder ? <FolderIcon /> : <FileIcon />}
                    onClick={(e) => changeSource(child.url)}
                  />
                ))}
                <TreeItem
                  nodeId={`${10000 + root.id}`}
                  label="+"
                  onClick={(e) => createSource()}
                />
              </TreeItem>
            ))}
          </TreeView>
        </Paper>
        <Paper style={{ flex: 1 }}>
          <iframe
            onLoad={(e) => console.log("iframe on load")}
            src={source}
            style={{ width: "100%", height: "100%", border: 0 }}
          ></iframe>
        </Paper>
      </div>
    </Layout>
  );
});
