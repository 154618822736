import axios from "axios";
import { action, makeAutoObservable, toJS } from "mobx";
import { api } from "../../services/api.service";

export class ExpenseStore {
  page = {
    loading: true,
    paymentModal: false,
    modal: {
      show: false,
      title: "",
      content: "",
      success: false,
    },
    confirmationModal: {
      show: false,
      title: "",
      content: "Are you sure?",
      success: false,
    },
  };

  data = {
    lookup: {
      companies: [],
      types: [],
      categories: [],
      subcategories: [],
      currencies: [],
      channels: [],
    },
    list: [],
    model: {
      name: undefined,
      company: {},
      type: {},
      category: {},
      sub_category: {},
      currency: undefined,
      billDate: undefined,
      billNumber: undefined,
      totalAmount: undefined,
      tax: undefined,
      paidAmount: undefined,
      attachment: undefined,
      attachment2: undefined,
      attachment3: undefined,
      description: "",
      approvalDate: Date,
      iban: "",
    } as any,
    payment: {
      payment_channel: {},
    } as any,
    roles: {} as any,
    summary: {} as any,
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action init = () => {
    console.log("Initializing Expense Store");

    // Reset Data
    this.page = {
      loading: true,
      paymentModal: false,
      modal: {
        show: false,
        title: "",
        content: "",
        success: false,
      },
      confirmationModal: {
        show: false,
        title: "",
        content: "Are you sure?",
        success: false,
      },
    };

    this.data = {
      lookup: {
        companies: [],
        types: [],
        categories: [],
        subcategories: [],
        currencies: [],
        channels: [],
      },
      list: [],
      model: {
        name: undefined,
        company: {},
        type: {},
        category: {},
        sub_category: {},
        currency: undefined,
        billDate: undefined,
        billNumber: undefined,
        totalAmount: 0,
        tax: 0,
        paidAmount: 0,
        attachment: undefined,
        attachment2: undefined,
        attachment3: undefined,
        description: "",
        iban: "",
        approvalDate: undefined,
      },
      payment: {
        payment_channel: {},
      },
      roles: {},
      summary: {},
    };
  };

  @action fetchLookups = async () => {
    const companies = await api.get("/companies");
    const types = await api.get("/bill-types");
    const categories = await api.get("/bill-categories");
    const subcategories = await api.get("/bill-sub-categories");
    const channels = await api.get("/payment-channels");

    this.data.lookup.companies = companies.data;
    this.data.lookup.types = types.data;
    this.data.lookup.categories = categories.data;
    this.data.lookup.subcategories = subcategories.data;
    this.data.lookup.channels = channels.data;
  };

  @action fetchList = async () => {
    const result = await api.get("/bills");

    this.data.list = result.data;
    this.page.loading = false;
  };

  @action fetchRoles = async (id: number) => {
    const result = await api.get("/bills/" + id + "/roles");
    this.data.roles = result.data;
  };

  @action fetch = async (id: number) => {
    const result = await api.get("/bills/" + id);
    this.data.model = result.data;
    this.page.loading = false;
  };

  @action fetchSummary = async (params: any) => {
    const result = await api.get("/bills/pivot", { params });
    this.data.summary = result.data;
    this.page.loading = false;
  };

  @action control = async () => {
    try {
      const result = await api.post(`/bills/${this.data.model.id}/control`, {
        approvalDate: this.data.model.approvalDate,
      });

      console.log(result);

      this.page.modal.title = "Success";
      this.page.modal.content = "Form successfully controlled";
      this.page.modal.show = true;
      this.page.modal.success = true;
      return true;
    } catch (e: any) {
      this.page.modal.title = "Error";
      this.page.modal.content = e.data;
      this.page.modal.show = true;
      this.page.modal.success = false;
      return false;
    }
  };

  @action approve = async () => {
    try {
      const result = await api.post(`/bills/${this.data.model.id}/approve`, {
        approvalDate: this.data.model.approvalDate,
      });

      this.page.modal.title = "Success";
      this.page.modal.content = "Form successfully approved";
      this.page.modal.show = true;
      this.page.modal.success = true;
      return true;
    } catch (e: any) {
      this.page.modal.title = "Error";
      this.page.modal.content = e.data;
      this.page.modal.show = true;
      this.page.modal.success = false;
      return false;
    }
  };

  @action pay = async () => {
    try {
      const result = await api.post(`/bills/${this.data.model.id}/pay`);
      this.page.modal.title = "Success";
      this.page.modal.content = "Form successfully paid";
      this.page.modal.show = true;
      this.page.modal.success = true;
      return true;
    } catch (e: any) {
      this.page.modal.title = "Error";
      this.page.modal.content = e.data;
      this.page.modal.show = true;
      this.page.modal.success = false;
      return false;
    }
  };

  @action complete = async () => {
    try {
      const result = await api.post(`/bills/${this.data.model.id}/complete`);
      this.page.modal.title = "Success";
      this.page.modal.content = "Form successfully completed";
      this.page.modal.show = true;
      this.page.modal.success = true;
      return true;
    } catch (e: any) {
      this.page.modal.title = "Error";
      this.page.modal.content = e.data;
      this.page.modal.show = true;
      this.page.modal.success = false;
      return false;
    }
  };

  @action reject = async () => {
    try {
      const result = await api.post(`/bills/${this.data.model.id}/reject`);
      this.page.modal.title = "Success";
      this.page.modal.content = "Form successfully rejected";
      this.page.modal.show = true;
      this.page.modal.success = true;
      return true;
    } catch (e: any) {
      this.page.modal.title = "Error";
      this.page.modal.content = e.data;
      this.page.modal.show = true;
      this.page.modal.success = false;
      return false;
    }
  };

  @action cancel = async () => {
    try {
      const result = await api.post(`/bills/${this.data.model.id}/cancel`);
      this.page.modal.title = "Success";
      this.page.modal.content = "Form successfully approved";
      this.page.modal.show = true;
      this.page.modal.success = true;
      return true;
    } catch (e: any) {
      this.page.modal.title = "Error";
      this.page.modal.content = e.data;
      this.page.modal.show = true;
      this.page.modal.success = false;
      return false;
    }
  };

  @action remove = async () => {
    try {
      const result = await api.post(`/bills/${this.data.model.id}/remove`);
      this.page.modal.title = "Success";
      this.page.modal.content = "Form successfully removed";
      this.page.modal.show = true;
      this.page.modal.success = false;
      return true;
    } catch (e: any) {
      this.page.modal.title = "Error";
      this.page.modal.content = e.data;
      this.page.modal.show = true;
      this.page.modal.success = false;
      return false;
    }
  };

  @action save = async () => {
    this.page.loading = true;

    var formdata = new FormData();

    formdata.set("data", JSON.stringify(this.data.model));
    formdata.set("files.attachment", this.data.model.attachment);
    formdata.set("files.attachment2", this.data.model.attachment2);
    formdata.set("files.attachment3", this.data.model.attachment3);

    if (this.data.model.id) {
      const result = await api.put("/bills/" + this.data.model.id, formdata);
      this.page.loading = false;

      this.page.modal.title = "Success";
      this.page.modal.content = "Form successfully saved";
      this.page.modal.show = true;
      this.page.modal.success = true;
      this.data.model = result.data;

      return result.data;
    } else {
      const result = await api.post("/bills", formdata);
      this.page.loading = false;

      this.page.modal.title = "Success";
      this.page.modal.content = "Form successfully saved";
      this.page.modal.show = true;
      this.page.modal.success = true;
      this.data.model = result.data;
      return result.data;
    }
  };

  @action paymentAdd = async () => {
    this.data.payment = { payment_channel: {} };
    this.page.paymentModal = true;
  };

  @action paymentUpdate = async (payment: any) => {
    this.data.payment = payment;
    if (!payment.payment_channel) payment.payment_channel = {};
    this.page.paymentModal = true;
  };

  @action savePayment = async () => {
    if (this.data.payment.id) {
      const result = await api.put(
        "/bills/" + this.data.model.id + "/payment/" + this.data.payment.id,
        this.data.payment
      );

      this.page.paymentModal = false;
    } else {
      const result = await api.post(
        "/bills/" + this.data.model.id + "/payment",
        this.data.payment
      );

      this.page.paymentModal = false;
    }

    await this.fetch(this.data.model.id);
  };
}
