import * as React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { Layout } from "../../../components/Layout";
import { useStores } from "../../../stores";
import { CustomerExperienceStore } from "../customer-experience.store";

interface IProps {
  history: any;
}

const store = new CustomerExperienceStore();

export const CustomerExperienceDashboardPage = observer((props: IProps) => {
  const { appStore } = useStores();

  React.useEffect(() => {}, [appStore]);

  return (
    <Layout public module="cx" page="dashboard" history={props.history}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          Customer Experience Module
        </Grid>
      </Grid>
    </Layout>
  );
});
