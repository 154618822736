import * as React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { Layout } from "../../../components/Layout";
import { useStores } from "../../../stores";
import { CustomerExperienceStore } from "../customer-experience.store";

interface IProps {
  history: any;
}

const store = new CustomerExperienceStore();

export const CustomerExperienceWhatsappPage = observer((props: IProps) => {
  const { appStore } = useStores();

  const init = async () => {
    await store.fetchChannels();
  };

  const checkSearch = (name: string) => {
    return name.indexOf(store.page.search) > -1;
  };

  React.useEffect(() => {
    init();
  }, [appStore]);

  return (
    <Layout public module="cx" page="dashboard" history={props.history}>
      <Grid container spacing={3} className="main-container">
        <Grid item xs={12}>
          <Paper className="main-paper">
            <div
              className="chat-wrapper"
              style={{ display: "flex", height: "100%" }}
            >
              <div
                className="chat-left"
                style={{
                  width: 300,
                  height: "100%",
                  padding: 5,
                  borderRight: "1px solid #ccc",
                }}
              >
                <div
                  className="chat-channels"
                  style={{ height: "100%", overflowY: "auto" }}
                >
                  <div style={{ marginTop: 25 }}>
                    <TextField
                      id="outlined-basic"
                      label="Search"
                      fullWidth
                      onChange={(e) => (store.page.search = e.target.value)}
                    />
                  </div>
                  <List component="nav" aria-label="secondary mailbox folders">
                    {store.page.channels.map(
                      (c: any, i: number) =>
                        checkSearch(c.name) && (
                          <ListItem
                            button
                            key={i}
                            onClick={async (e) =>
                              await store.fetchChannel(c.data_directory)
                            }
                          >
                            <ListItemText primary={`${c.name}`} />
                          </ListItem>
                        )
                    )}
                  </List>
                </div>
              </div>

              {/* 15201974524 */}

              <div
                className="chat-right"
                style={{ padding: 25, flex: 1, height: "100%" }}
              >
                {store.page.channel && (
                  <div
                    className="chat-content"
                    style={{ height: "100%", overflowY: "auto" }}
                  >
                    {store.page.channel.map((c: any, i: number) => (
                      <div
                        className={`chat-item ${
                          c.sender.alias && c.sender.alias.name == "Customer"
                            ? "customer"
                            : ""
                        }`}
                        style={{
                          marginBottom: 15,
                          display: "flex",
                        }}
                      >
                        <div
                          className={`chat-item__wrapper`}
                          style={{
                            padding: 5,
                            border: "1px solid #ccc",
                            borderRadius: 8,
                            width: "50%",
                          }}
                        >
                          <div
                            className="chat-item__header"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              borderBottom: "1px solid #f1f1f1",
                            }}
                          >
                            <div>
                              {c.sender.alias
                                ? c.sender.alias.name
                                : c.sender.name}
                            </div>
                            <div>{c.timestamp}</div>
                          </div>
                          <div
                            className="chat-item__content"
                            style={{ padding: 10 }}
                          >
                            {c.content.text}

                            {c.content.file && (
                              <div>
                                <div>{c.content.comment}</div>

                                <br />

                                <a
                                  href={c.content.file.url}
                                  target="_blank"
                                  style={{
                                    display: "block",
                                    textAlign: "center",
                                  }}
                                >
                                  {c.content.thumbnail ? (
                                    <img
                                      style={{ width: "200px" }}
                                      src={`data:image/jpeg;base64,${c.content.thumbnail.blur_data}`}
                                    />
                                  ) : (
                                    <span>{c.content.file.name}</span>
                                  )}
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
});
