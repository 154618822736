import * as React from "react";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { Layout } from "../../../components/Layout";
import { useStores } from "../../../stores";
import { ExpenseStore } from "../expense.store";
import {
  IDetailProps,
  IFilterProps,
  IHeaderProps,
  SortableTable,
} from "../../../components/SortableTable";
import { HelperService } from "../../../services/helper.service";

interface IProps {
  history: any;
}

const expenseStore = new ExpenseStore();

var headers: IHeaderProps[] = [
  {
    name: "billDate",
    text: "Date",
    type: "date",
    sortable: true,
  },
  {
    name: "name",
    text: "Company",
    type: "string",
    sortable: true,
  },
  {
    name: "type.name",
    text: "Type",
    type: "string",
    sortable: true,
  },
  {
    name: "category.name",
    text: "Category",
    type: "string",
    sortable: true,
  },
  {
    name: "sub_category.name",
    text: "Subcategory",
    type: "string",
    sortable: true,
  },
  {
    name: "billNumber",
    text: "Number",
    sortable: true,
  },
  {
    name: "totalAmount",
    text: "Total",
    sortable: true,
    render: (data: any) => (
      <span>
        {HelperService.currencyFormat(data.totalAmount, data.currency)}
      </span>
    ),
  },
  {
    name: "tax",
    text: "Tax",
    sortable: true,
    render: (data: any) => (
      <span>{HelperService.currencyFormat(data.tax, data.currency)}</span>
    ),
  },
  // {
  //   name: "paymentTotal",
  //   text: "Paid Total",
  //   render: (data: any) => (
  //     <span>
  //       {HelperService.currencyFormat(data.paymentTotal, data.currency)}
  //     </span>
  //   ),
  // },
  // {
  //   name: "paymentRemaining",
  //   text: "Remaining",
  //   render: (data: any) => (
  //     <span>
  //       {HelperService.currencyFormat(data.paymentRemaining, data.currency)}
  //     </span>
  //   ),
  // },
  {
    name: "status",
    text: "Status",
    sortable: true,
  },
];

var details: IDetailProps = {
  title: "Payments",
  relation: "payments",
  columns: [
    {
      name: "bank",
      text: "Bank",
    },
    {
      name: "amount",
      text: "Amount",
      type: "currency",
      currency: "currency",
    },
    {
      name: "iban",
      text: "IBAN",
    },
    {
      name: "paymentDate",
      text: "Payment Date",
      type: "date",
    },
  ],
};

var filters: IFilterProps[] = [
  {
    name: "billDate_gte",
    label: "Date from",
    type: "date",
  },
  {
    name: "billDate_lte",
    label: "Date to",
    type: "date",
  },
  {
    name: "billNumber",
    label: "Number",
    type: "string",
  },
  {
    name: "name",
    label: "Name",
    type: "string",
  },
  {
    name: "type.name",
    label: "Type",
    type: "lookup",
    endpoint: "/bill-types",
    key: "name",
  },
  {
    name: "category.name",
    label: "Category",
    type: "lookup",
    endpoint: "/bill-categories",
    key: "name",
  },
  {
    name: "company.name",
    label: "Company",
    type: "lookup",
    endpoint: "/companies",
    key: "name",
  },
  {
    name: "status",
    label: "Status",
    type: "lookup",
    options: ["pending", "approved", "rejected", "completed"],
  },
];

export const ExpenseReportPage = observer((props: IProps) => {
  const { appStore } = useStores();

  React.useEffect(() => {
    expenseStore.init();
  }, [appStore]);

  return (
    <Layout module="bill" page="report" history={props.history}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SortableTable
            endpoint="/bills/report"
            headers={headers}
            details={details}
            filters={filters}
            onEditClicked={(id) => {
              window.open(`/expense/${id}/detail`);
              // props.history.push(`/expense/${id}/detail`);
            }}
          />
        </Grid>
      </Grid>
    </Layout>
  );
});
