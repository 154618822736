import axios from "axios";
import { action, makeAutoObservable, observable, toJS } from "mobx";
import { api } from "../../services/api.service";

export class CustomerExperienceStore {
  page = {
    loading: true,
    channels: [],
    number: "",
    selectedId: "",
    channel: [],
    search: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action fetchChannels = async () => {
    const result = await api.get("/customer-experience/whatsapp/channels");
    this.page.channels = result.data;
  };

  @action fetchChannel = async (id: any) => {
    const result = await api.get(
      "/customer-experience/whatsapp/channels/" + id
    );
    this.page.channel = result.data;
  };
}
