import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "date-fns";

import { Dashboard } from "./modules/dashboard/Dashboard";
import { Login } from "./modules/auth/Login";
import { Logout } from "./modules/auth/Logout";
import { NotFound } from "./modules/error/NotFound";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DriveRoutes } from "./modules/drive/drive.module";
import { ExpenseRoutes } from "./modules/expense/expense.module";
import { AccountRoutes } from "./modules/account/account.module";
import { CustomerExperienceRoutes } from "./modules/customer-experience/customer-experience.module";
import { DaskRoutes } from "./modules/dask/dask.module";

const App = () => {
  React.useEffect(() => {}, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Router>
        <Switch>
          <Route path="/" exact={true} component={Dashboard}></Route>
          <Route path="/auth/login" component={Login}></Route>
          <Route path="/auth/logout" component={Logout}></Route>
        </Switch>
        <AccountRoutes />
        <ExpenseRoutes />
        <CustomerExperienceRoutes />
        <DriveRoutes />
        <DaskRoutes />
      </Router>
    </MuiPickersUtilsProvider>
  );
};

export default App;
