import * as React from "react";
import { Route, Switch } from "react-router";
import { DaskDashboardPage } from "./pages/dask.page.dashboard";

interface IProps {
  subpath?: string;
}

export const DaskRoutes = (props: IProps) => (
  <Switch>
    <Route path="/dont-ask-me" exact component={DaskDashboardPage}></Route>
  </Switch>
);
