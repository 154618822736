import { action, makeAutoObservable, toJS } from "mobx";
import { api } from "../services/api.service";

interface IUser {
  id?: Number;
  token?: string;
  username?: string;
  email?: string;
}

export default class AppStore {
  user: IUser = {};
  roles: any[] = [];
  role_allowed = false;

  name: string = "Beeshopify";
  menu = "";
  submenu: string | undefined = "";
  menuOpen = false;

  error = {
    has_error: false,
    message: "",
  };

  constructor() {
    console.log("Initializing App Store");
    makeAutoObservable(this);
  }

  @action setMenuOpen() {
    this.menuOpen = !this.menuOpen;
  }

  @action setMenu = (menu: string) => {
    var parts = menu.split(".");

    this.menu = parts[0];
    this.submenu = parts.length > 1 ? parts[1] : undefined;
  };

  @action checkUser = async () => {
    const userdata = localStorage.getItem("crm:user");

    if (userdata) {
      this.user = JSON.parse(userdata);
    }
  };

  @action checkRole = async (isPublic?: boolean) => {
    if (isPublic) {
      this.role_allowed = true;
      return;
    }

    const overrides = [
      {
        controller: "dashboard",
        action: "index",
        enabled: true,
      },
      {
        controller: "account",
        action: "settings",
        enabled: true,
      },
    ];

    const result = await api.get("/account/roles");
    this.roles = [...result.data, ...overrides];

    var exists = this.roles.find(
      (x: any) => x.controller == this.menu && x.action == this.submenu
    );

    this.role_allowed = exists !== undefined;
  };

  @action login = async (username: string, password: string) => {
    try {
      const { data } = await api.post("auth/local", {
        identifier: username,
        password: password,
      });

      let userdata = {
        token: data.jwt,
        id: data.user.id,
        email: data.user.email,
        username: data.user.username,
      };

      localStorage.setItem("crm:user", JSON.stringify(userdata));

      this.user = userdata;

      return data;
    } catch (e) {
      console.log(e);
      return "error";
    }
  };

  @action logout = async () => {
    localStorage.removeItem("crm:user");
    this.user = {};

    window.location.reload();
  };
}
