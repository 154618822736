import {
  Button,
  ButtonGroup,
  createStyles,
  withStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Collapse,
  Box,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { api, apiURL } from "../services/api.service";
import EditIcon from "@material-ui/icons/Visibility";
import { Loading } from "./Loading";
import moment from "moment";
import { HelperService } from "../services/helper.service";
import { KeyboardDatePicker } from "@material-ui/pickers";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

export type Order = "asc" | "desc";
export type IColumnType = "string" | "number" | "date" | "currency";
export type IFilterType =
  | "string"
  | "number"
  | "date"
  | "date_range"
  | "currency"
  | "lookup"
  | "relation";

export type ICurrencyType = "TRY" | "USD" | "EUR";
export interface IHeaderProps {
  name: string;
  text: string;
  sortable?: boolean;
  align?: "center" | "left" | "right";
  type?: IColumnType;
  currency?: string;
  order?: Order;
  orderBy?: string;
  handleRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  render?: (data: any) => JSX.Element;
}

export interface IDetailProps {
  title?: string;
  relation: string;
  columns: IHeaderProps[];
}

export interface IFilterProps {
  name: string;
  label: string;
  type: IFilterType;
  options?: any[];
  endpoint?: string;
  key?: string;
}

const getObjectValue = (obj: any, key?: string) => {
  if (!key || !obj) return "";
  var arr = key.split(".");
  while (arr.length && (obj = obj[arr.shift() as string]));
  return obj;
};

const getRowData = (row: any, header: IHeaderProps) => {
  let value = getObjectValue(row, header.name);

  switch (header.type) {
    case "number":
      break;
    case "date":
      var parsed = moment(value);
      if (parsed.isValid()) value = moment(value).format("DD/MM/yyyy");
      break;
    case "currency":
      var symbol = getObjectValue(row, header.currency);
      value = HelperService.currencyFormat(value, symbol || "EUR");
      break;
    default:
      break;
  }

  return value;
};

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontSize: 12,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export const SortableTableHead = (props: IHeaderProps) => {
  return props.sortable ? (
    <StyledTableCell
      sortDirection={props.orderBy === props.name ? props.order : false}
      align={props.align}
    >
      <TableSortLabel
        active={props.orderBy === props.name}
        direction={props.orderBy === props.name ? props.order : "asc"}
        onClick={(e) => props.handleRequestSort!(e, props.name)}
      >
        {props.text}
      </TableSortLabel>
    </StyledTableCell>
  ) : (
    <StyledTableCell align={props.align}>{props.text}</StyledTableCell>
  );
};

const Row = (props: any) => {
  const { data, headers, details } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow key={data.id}>
        {details && (
          <StyledTableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
        )}
        {headers.map((header: IHeaderProps, index: number) => (
          <StyledTableCell key={index} align={header.align} scope="row">
            {header.render ? header.render(data) : getRowData(data, header)}
          </StyledTableCell>
        ))}
        <StyledTableCell align="center">
          <ButtonGroup size="small" variant="text">
            <Button onClick={() => props.onEditClicked(data.id)}>
              <EditIcon />
            </Button>
          </ButtonGroup>
        </StyledTableCell>
      </TableRow>
      {props.details && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {data[details.relation] && data[details.relation].length > 0 ? (
                <Box sx={{ margin: 1 }}>
                  {props.details.title && (
                    <Typography variant="h6" gutterBottom component="div">
                      {props.details.title}
                    </Typography>
                  )}
                  <Table
                    size="small"
                    style={{ marginTop: 15, marginBottom: 35 }}
                  >
                    <TableHead>
                      <TableRow>
                        {details.columns.map(
                          (header: IHeaderProps, index: number) => (
                            <TableCell key={index}>{header.text}</TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data[details.relation].map(
                        (relation: any, relationi: number) => (
                          <TableRow key={relationi}>
                            {details.columns.map(
                              (detailcolumn: any, detailcolumni: number) => (
                                <TableCell key={detailcolumni}>
                                  {detailcolumn.render
                                    ? detailcolumn.render(relation)
                                    : getRowData(relation, detailcolumn)}
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                <Box>
                  <TableCell>
                    {props.details.title && (
                      <Typography variant="h6" gutterBottom component="div">
                        No {details.title} found
                      </Typography>
                    )}
                  </TableCell>
                </Box>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

interface ISortableTableProps {
  endpoint: string;
  sort?: string;
  headers: IHeaderProps[];
  filters?: IFilterProps[];
  details?: IDetailProps;
  onEditClicked: (id: number) => void;
  children?: any;
}

export const SortableTable = (props: ISortableTableProps) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState(props.sort);
  const [order, setOrder] = React.useState<Order>("desc");
  const [filters, setFilter] = React.useState<any>({});
  const [summary, setSummary] = React.useState<any>({});
  const [propFilters, setpropFilters] = React.useState<any>([]);

  const fetchData = async (
    perPage: number,
    page: number,
    order?: string,
    orderBy?: string,
    filtered?: any
  ) => {
    setLoading(true);

    const params: any = {
      _start: page * perPage,
      _limit: perPage,
    };

    if (orderBy) {
      params["_sort"] = `${orderBy}:${order}`;
    }

    const response = await api.get(props.endpoint, {
      params: { ...params, ...filtered },
    });

    if (props.filters) {
      let { filters } = props;

      for (var i = 0; i < filters.length; i++) {
        let f = filters[i];

        if (f.type == "lookup" && f.endpoint && f.key) {
          let r = await api.get(f.endpoint);
          f.options = r.data.map((x: any) => x[f.key as string]);
        }
      }

      setpropFilters(filters);
      console.log(filters);
    }

    setData(response.data.result);
    setCount(response.data.count);
    setSummary(response.data.summary);
    setLoading(false);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    fetchData(rowsPerPage, page, direction, property, filters);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    setRowsPerPage(perPage);
    setPage(0);

    fetchData(perPage, 0, order, orderBy, filters);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchData(rowsPerPage, newPage, order, orderBy, filters);
  };

  React.useEffect(() => {
    fetchData(rowsPerPage, page, order, orderBy);
  }, []);

  const onFilterChange = async (
    filter: IFilterProps,
    value: any,
    instant = false
  ) => {
    var key = filter.name;

    switch (filter.type) {
      case "string":
        key += "_contains";
        break;
      case "lookup":
        break;
      default:
        break;
    }

    if (value) {
      filters[key] = value;
      setFilter({ ...filters });
    } else {
      delete filters[key];
      setFilter({ ...filters });
    }

    if (instant) {
      await fetchData(rowsPerPage, page, order, orderBy, filters);
    }
  };

  const onFilterClear = async () => {
    setFilter({});
    await fetchData(rowsPerPage, page, order, orderBy, {});
  };

  const onFilterSubmit = async (e: any) => {
    e.preventDefault();
    await fetchData(rowsPerPage, page, order, orderBy, filters);
  };

  const onExport = async (e: any) => {
    e.preventDefault();

    const qs = Object.keys(filters)
      .map((key) =>
        filters[key] instanceof Date
          ? `${key}=${filters[key].toISOString()}`
          : `${key}=${filters[key]}`
      )
      .join("&");

    const url = apiURL + props.endpoint + "/export?" + qs;
    window.open(url);
  };

  return (
    <div className="lg-sortableTable">
      <Card elevation={2} style={{ marginBottom: 15 }}>
        <CardHeader title="Filters"></CardHeader>
        <CardContent>
          {propFilters && (
            <form onSubmit={onFilterSubmit}>
              <Grid container spacing={3}>
                {propFilters.map((filter: IFilterProps, index: number) => (
                  <Grid item sm={4} key={index}>
                    {filter.type == "string" && (
                      <FormControl fullWidth style={{ marginTop: 16 }}>
                        <TextField
                          fullWidth
                          id={filter.name}
                          label={filter.label}
                          onChange={(e) =>
                            onFilterChange(filter, e.target.value)
                          }
                          value={filters[filter.name + "_contains"] || ""}
                        />
                      </FormControl>
                    )}
                    {filter.type == "lookup" && (
                      <FormControl fullWidth style={{ marginTop: 16 }}>
                        <InputLabel>{filter.label}</InputLabel>
                        <Select
                          onChange={(e) =>
                            onFilterChange(filter, e.target.value, true)
                          }
                          value={filters[filter.name] || ""}
                        >
                          <MenuItem value="">None</MenuItem>
                          {filter.options &&
                            filter.options.map((option: any, i: number) => (
                              <MenuItem key={i} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                    {filter.type == "date" && (
                      <FormControl fullWidth>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          label={filter.label}
                          value={filters[filter.name]}
                          onChange={(date) => onFilterChange(filter, date)}
                        />
                      </FormControl>
                    )}
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <Button type="submit">Apply</Button>
                  <Button onClick={onFilterClear}>Clear</Button>
                  <Button onClick={onExport}>Export</Button>
                </Grid>
              </Grid>
            </form>
          )}
        </CardContent>
      </Card>

      {summary && Object.keys(summary).length > 0 && (
        <div className="cards">
          <Grid container spacing={3}>
            {Object.keys(summary).map((item, i) => (
              <Grid item key={i} sm={3}>
                <Card elevation={2} style={{ marginBottom: 15 }}>
                  <CardHeader title={summary[item].label}></CardHeader>
                  <CardContent>
                    <div style={{ fontSize: 20 }}>
                      {HelperService.currencyFormat(summary[item].value, "EUR")}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      <TableContainer component={Paper} style={{ height: 600, width: 'calc(100vw - 285px)' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {props.details && <StyledTableCell></StyledTableCell>}
              {props.headers.map((header: IHeaderProps, index: number) => (
                <SortableTableHead
                  key={index}
                  name={header.name}
                  text={header.text}
                  sortable={header.sortable}
                  order={order}
                  orderBy={orderBy}
                  handleRequestSort={handleRequestSort}
                />
              ))}
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <Loading />
          ) : (
            <TableBody>
              {!data || data.length == 0 ? (
                <div style={{ padding: 20 }}>No result!</div>
              ) : (
                data.map((row: any) => (
                  <Row
                    key={row.id}
                    data={row}
                    headers={props.headers}
                    details={props.details}
                    onEditClicked={props.onEditClicked}
                  />
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
