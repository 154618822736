import { action, makeAutoObservable } from "mobx";
import { api } from "../../services/api.service";

export class AccountStore {
  page = {
    loading: true,
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action init = () => {
    // Reset Data
    this.page = {
      loading: true,
    };
  };

  @action change_password = async (password: string, passwordConfirmation: string) => {
    this.page.loading = true;

    try {
      var result = await api.put("account/password_reset", { password, passwordConfirmation });

      this.page.loading = false;
      return true;
    }
    catch (e: any) {
      this.page.loading = false;
      return false;
    }
  }
}
