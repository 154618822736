export class HelperService {
  static currencyFormat = (amount: number | undefined, symbol: string) => {
    if (!symbol) return amount;

    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: symbol,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount || 0);
  };

  static checkObject = (obj: any, key: string) => {
    // return true;
    return obj[key];
  };
}
