import * as React from "react";
import { Route, Switch } from "react-router";
import { ExpenseControlPage } from "./pages/expense.page.control";
import { ExpenseDashboardPage } from "./pages/expense.page.dasboard";
import { ExpenseDetailPage } from "./pages/expense.page.detail";
import { ExpenseHistoryPage } from "./pages/expense.page.history";
import { ExpenseMePage } from "./pages/expense.page.me";
import { ExpensePendingPage } from "./pages/expense.page.pending";
import { ExpenseReportPage } from "./pages/expense.page.report";
import { ExpenseSummaryPage } from "./pages/expense.page.summary";

interface IProps {
  subpath?: string;
}

export const ExpenseRoutes = (props: IProps) => (
  <Switch>
    <Route path="/expense" exact component={ExpenseDashboardPage}></Route>
    <Route path="/expense/:id/detail" component={ExpenseDetailPage}></Route>
    <Route path="/expense/report" component={ExpenseReportPage}></Route>
    <Route path="/expense/summary" component={ExpenseSummaryPage}></Route>
    <Route path="/expense/create" component={ExpenseDetailPage}></Route>
    <Route path="/expense/pending" component={ExpensePendingPage}></Route>
    <Route path="/expense/history" component={ExpenseHistoryPage}></Route>
    <Route path="/expense/me" component={ExpenseMePage}></Route>
    <Route path="/expense/control" component={ExpenseControlPage}></Route>
  </Switch>
);
