import * as React from "react";
import { Route, Switch } from "react-router";
import { CustomerExperienceDashboardPage } from "./pages/customer-experience.page.dasboard";
import { CustomerExperienceWhatsappPage } from "./pages/customer-experience.page.whatsapp";

interface IProps {
  subpath?: string;
}

export const CustomerExperienceRoutes = (props: IProps) => (
  <Switch>
    <Route
      path="/customer-experience"
      exact
      component={CustomerExperienceDashboardPage}
    ></Route>
    <Route
      path="/customer-experience/whatsapp"
      exact
      component={CustomerExperienceWhatsappPage}
    ></Route>
  </Switch>
);
